import React, { useState, useEffect } from "react";
import tasksData from "./Tasks17025.json"; // Adjust the path as needed
import CollapsibleTable from "./CollapsibleTable";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function DisplayJsonTable() {

  const totalChecklistItems = tasksData.reduce(
    (acc, task) => acc + task["Checklist Items"].length,
    0
  );

  return (
    <>
      <Typography variant="h3" align="center">
        Acreditação ISO17025
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <Box
            sx={{
              bgcolor: "rgba(18,85,112,0.2)",
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              height: 150,
              maxWidth: 580,
            }}
          >
            <Typography variant="h5" align="center">
              Número de Itens
            </Typography>
            <Typography
              variant="h3"
              align="center"
              sx={{ marginTop: 2, opacity: 0.7 }}
            >
              {tasksData.length}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Box
            sx={{
              bgcolor: "rgba(18,85,112,0.2)",

              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              height: 150,
              maxWidth: 580,
            }}
          >
            <Typography variant="h5" align="center">
              Número de Tarefas
            </Typography>
            <Typography
              variant="h3"
              align="center"
              sx={{ marginTop: 2, opacity: 0.7 }}
            >
              {totalChecklistItems}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Box
            sx={{
              bgcolor: "rgba(18,85,112,0.2)",
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              height: 150,
              maxWidth: 580,
            }}
          >
            <Typography variant="h5" align="center">
              Tarefas Finalizadas
            </Typography>
            <Typography
              variant="h3"
              align="center"
              sx={{ marginTop: 2, opacity: 0.7 }}
            >
              0/ {totalChecklistItems} (0%)
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <br></br>
      <CollapsibleTable />
    </>
  );
}

export default DisplayJsonTable;
