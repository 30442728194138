import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  MenuItem,
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";

const units = [
  { label: "Pascal (Pa)", value: "Pa" },
  { label: "Bar", value: "Bar" },
  { label: "mbar", value: "mbar" },
  { label: "psi", value: "psi" },
  { label: "kgf/cm2", value: "kgf/cm2" },
  { label: "mmHg = Torr", value: "mmHg = Torr" },
  { label: "inHg", value: "inHg" },
  { label: "mH2O", value: "mH2O" },
  { label: "inH2O", value: "inH2O" },
  { label: "atm", value: "atm" },
];

const conversionFactors = {
  Pa: 1,
  Bar: 1e-5,
  mbar: 0.01,
  psi: 1.450377e-4,
  "kgf/cm2": 1.019716e-5,
  "mmHg = Torr": 7.500627e-3,
  inHg: 2.953003e-4,
  mH2O: 1.019716e-4,
  inH2O: 4.0145131e-3,
  atm: 9.86923e-6,
};

const PressureConverter = () => {
  const [value, setValue] = useState("");
  const [valueBR, setValueBR] = useState("");
  const [unitFrom, setUnitFrom] = useState("Pa");
  const [unitTo, setUnitTo] = useState("Pa");
  const [result, setResult] = useState("");
  const [resultBR, setResultBR] = useState("");
  
  const handleChange = (e) => {
    let inputValue = e.target.value;
  
    // Remove non-numeric characters except for commas and periods
    inputValue = inputValue.replace(/[^\d,]/g, "");
  
    // Replace commas with periods to standardize
    let formattedValue = inputValue.replace(/\./g, "").replace(",", ".");
  
    // Convert to a number
    let numberValue = parseFloat(formattedValue);
  
    // Convert it back to BR format for display
    let displayValue;
    if (!isNaN(numberValue)) {
      displayValue = numberValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      });
    } else {
      displayValue = "";
    }
  
    // Update state for both values
    setValueBR(e.target.value);
    setValue(numberValue);
  };
  
  const handleConvert = () => {
    if (isNaN(value)) {
      setResult("Not a Number");
      setResultBR("");
      return;
    }
  
    // Perform conversion
    let valueInPascals = value / conversionFactors[unitFrom];
    let convertedValue = valueInPascals * conversionFactors[unitTo];
    
    // Update result
    const convertedValueStr = convertedValue.toFixed(4);
    setResult(convertedValueStr);
    
    // Update resultBR after setting result
    const formattedResultBR = parseFloat(convertedValueStr).toLocaleString("pt-BR", {
      minimumFractionDigits: 4,
    });
    setResultBR(formattedResultBR);
  };
  
  // Trigger conversion when value or units change
  useEffect(() => {
    handleConvert();
  }, [value, unitFrom, unitTo]);
  

  return (
    <Container maxWidth="sm" sx={{ minHeight: 555 }}>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Conversor de Pressão
        </Typography>
        <TextField
          label="Valor"
          variant="outlined"
          type="text"
          value={valueBR}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              select
              label="De"
              value={unitFrom}
              onChange={(e) => setUnitFrom(e.target.value)}
              fullWidth
              margin="normal"
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Para"
              value={unitTo}
              onChange={(e) => setUnitTo(e.target.value)}
              fullWidth
              margin="normal"
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="h6">Resultado: {resultBR}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PressureConverter;
