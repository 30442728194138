import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";

import TasksOverview from "./TasksOverview";
import TeamOverview from "./TeamOverview";
import Footer from "../../common/Footer";

function Dashboard() {
  const [userAuth, setUserAuth] = useState("");

  // useEffect(() => {
  //   async function fetchUser() {
  //     try {
  //       const currentUser = await fetchUserAttributes();
  //       setUserAuth(currentUser);
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //   }
  //   fetchUser();
  // }, []);

  return (
    <Container maxWidth="xlg">
      {/* <TasksOverview /> */}

      {/* <br></br> */}

      <Box sx={{ flexGrow: 1 }}>
        {/* <Row className='px-2'>
					<h1>{userProfile} Dashboard</h1>
				</Row> */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                bgcolor: "rgba(0, 255, 0, 0.5)",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 150,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                Tasks Ontrack
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                12
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                bgcolor: "rgba(255, 255, 0, 0.5)",

                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 150,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                Tasks within 7 days
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                7
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                bgcolor: "rgba(255, 0, 0, 0.7)",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 150,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                Tasks in Past Due
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                3
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Box sx={{ flexGrow: 1 }}>
        {/* <Row className='px-2'>
					<h1>{userProfile} Dashboard</h1>
				</Row> */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={8}>
            <Box
              sx={{
                // bgcolor: 'rgba(0, 255, 0, 0.5)',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 400,
                // maxWidth: 680,
              }}
            >
              {/* <Typography variant="h5" align="center">
								Task Ontrack
							</Typography> */}
              <TasksOverview />
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                // bgcolor: 'rgba(0, 255, 0, 0.5)',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 400,
                // maxWidth: 680,
              }}
            >
              {/* <Typography variant="h5" align="center">
								Task Ontrack
							</Typography> */}
              <TeamOverview />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default withAuthenticator(Dashboard);
