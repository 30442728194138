export const tasksInput = [
    {
        start: "01/02/2024",
        end: "15/02/2024",
        name: "Some Project 1",
        id: "ProjectSample1",
        // progress: 25,
        type: "project",
        hideChildren: false,
        title: "Task 1", 

        description: "Description 1",
        owner: "TVasconcelos",
        status: "Ongoing", 
        progress: 70,
        deadline: "15/02/2024", 
        priority:"High"
    },
    {
        start: "01/02/2024",
        end: "15/03/2024",
        name: "Some Project 2",
        id: "ProjectSample2",
        // progress: 25,
        type: "project",
        hideChildren: false,
        title: "Task 2", 

        description: "Description 2",
        owner: "TJ",
        status: "Delayed", 
        progress: 25,
        deadline: "15/02/2024", 
        priority:"High"
    },
    {
        start: "01/02/2024",
        end: "15/02/2024",
        name: "Some Project 3",
        id: "ProjectSample3",
        // progress: 25,
        type: "project",
        hideChildren: false,
        title: "Task 3", 

        description: "Description 3",
        owner: "TV",
        status: "Completed", 
        progress: 100,
        deadline: "15/02/2024", 
        priority:"Low"
    },
    
    // {
    //     start: new Date(2024, 2, 1),
    //     end: new Date(2024, 2, 2, 12, 28),
    //     name: "Idea",
    //     id: "Task 0",
    //     // progress: 45,
    //     type: "task",
    //     project: "ProjectSample",
       
    //     title: "Task 2", 
    //     description: "Description 2",
    //     owner: "TVilela",
    //     status: "Delayed", 
    //     progress: 30,
    //     deadline: new Date(2024, 2, 2, 12, 28), 
    //     priority:"Medium"
    // },
    // {
    //     start: new Date(2024, 2, 2),
    //     end: new Date(2024, 2, 4, 0, 0),
    //     name: "Research",
    //     id: "Task 1",
    //     progress: 25,
    //     // dependencies: ["Task 0"],
    //     type: "task",
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 4),
    //     end: new Date(2024, 2, 7, 0, 0),
    //     name: "Discussion with team",
    //     id: "Task 2",
    //     progress: 70,
    //     // dependencies: ["Task 1"],
    //     type: "task",
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 7),
    //     end: new Date(2024, 2,8),
    //     name: "Discussion with team 2",
    //     id: "Task 0",
    //     progress: 0,
    //     // isDisabled: true,
    //     dependencies: ["Task 2"],
    //     type: "task",
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 8),
    //     end: new Date(2024, 2, 9, 0, 0),
    //     name: "Developing",
    //     id: "Task 3",
    //     // progress: 2,
    //     // dependencies: ["Task 2"],
    //     type: "task",
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 8),
    //     end: new Date(2024, 2, 10),
    //     name: "Review",
    //     id: "Task 4",
    //     type: "task",
    //     // progress: 70,
    //     // dependencies: ["Task 2"],
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 15),
    //     end: new Date(2024, 2,15),
    //     name: "Release",
    //     id: "Task 6",
    //     // progress: currentDate.getMonth(),
    //     type: "task",
    //     // dependencies: ["Task 4"],
    //     project: "ProjectSample"
    // },
    // {
    //     start: new Date(2024, 2, 18),
    //     end: new Date(2024, 2, 19),
    //     name: "Party Time",
    //     id: "Task 9",
    //     // progress: 0,
    //     // isDisabled: true,
    //     type: "task",
    //     project: "ProjectSample"
    // },
]

export const optionsStatus= [
    { value: 'Ongoing', label: 'Ongoing' },
    { value: 'Delayed', label: 'Delayed' },
    { value: 'Completed', label: 'Completed' },
  ];

  export  const optionsPriority = [
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' },
  ];


  export  const optionsOwner = [
    { id: 1, name: 'TVasconcelos' },
    { id: 2, name: 'TWilliams' },
    { id: 3, name: 'TVilela' },
  ];