import React, { useState } from "react";
import TaskForm from "./TaskForm";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const App = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      title: "Title 1",
      owner: "TVasconcelos",
      subtasks: [
        { id: 1, title: "SubTitle 1", owner: "TMaria" },
        { id: 2, title: "SubTitle 2", owner: "TFerreira" },
      ],
    },
    {
      id: 2,
      title: "Title 2",
      owner: "TVasconcelos",
      subtasks: [
        { id: 1, title: "SubTitle 1", owner: "TMaria" },
        { id: 2, title: "SubTitle 2", owner: "TFerreira" },
      ],
    },
  ]);
  const [editedTask, setEditedTask] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const addTask = (newTask) => {
    setTasks([...tasks, newTask]);
    setIsFormOpen(false);
  };

  const editTask = (task) => {
    setEditedTask(task);
    setIsFormOpen(true);
  };

  const updateTask = (updatedTask) => {
    const updatedTasks = tasks.map((task) =>
      task.id === updatedTask.id ? updatedTask : task
    );
    setTasks(updatedTasks);
    setIsFormOpen(false);
  };

  const handleClose = () => {
    setIsFormOpen(false);
    setEditedTask(null);
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  return (
    <>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h1">Task Manager</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setIsFormOpen(true)}>
            Add Task
          </Button>
        </Grid>
        <Grid item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task) => (
                  <React.Fragment key={task.id}>
                    <TableRow>
                      <TableCell>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {task.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Subtask Title</TableCell>
                                    <TableCell>Subtask Owner</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {task.subtasks.map((subtask) => (
                                    <TableRow key={subtask.id}>
                                      <TableCell>{subtask.title}</TableCell>
                                      <TableCell>{subtask.owner}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                      <TableCell>{task.owner}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => editTask(task)}
                        >
                          Edit Task
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* TaskForm Dialog */}
        <Dialog
          open={isFormOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {editedTask ? "Edit Task" : "Add Task"}
          </DialogTitle>
          <DialogContent>
            <TaskForm
              onSubmit={addTask}
              initialValues={editedTask}
              onUpdate={updateTask}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ marginTop: 4, overflowY: "auto" }}
      >
        {/* <Table sx={{ minWidth: "600px", overflowY: "auto" }}>
        <TableHead> */}
        <Table
          sx={{
            minWidth: "800px",
            overflowY: "auto",
          }}
        >
          <TableRow>
            <TableCell
              sx={{ width: "2px", backgroundColor: "red" }}
            ></TableCell>
            <TableCell sx={{ minWidth: 100, backgroundColor: "lightgreen" }}>
              Title
            </TableCell>
            <TableCell sx={{ minWidth: 200, backgroundColor: "lightblue" }}>
              Owner
            </TableCell>
            <TableCell sx={{ minWidth: 100, backgroundColor: "lightyellow" }}>
              Action
            </TableCell>
          </TableRow>
        </Table>

        {/* </TableHead> */}
        {/* <TableBody> */}
        {tasks.map((task) => (
          // Render task row
          <React.Fragment key={task.id}>
            <Card
              sx={{
                // minWidth: "800px",
                marginTop: 2,
                borderRadius: "10px",
              }}
            >
              <Table
                sx={{
                  minWidth: "800px",
                  overflowY: "auto",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ width: "2px", backgroundColor: "red" }}
                    ></TableCell>
                    <TableCell sx={{ minWidth: 100, backgroundColor: "green" }}>
                      {task.title}
                    </TableCell>
                    <TableCell sx={{ minWidth: 200, backgroundColor: "blue" }}>
                      {task.owner}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 80, backgroundColor: "pink" }}>
                       
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Card>

            {/* Nested map to render subtasks */}
            {task.subtasks.map((subtask) => (
              <Card
                sx={{
                  // minWidth: "800px",
                  marginTop: 1,
                  borderRadius: "10px",
                }}
              >
                <Table
                  sx={{
                    minWidth: "800px",
                    // marginTop: 1,
                  }}
                >
                  <TableBody>
                    <TableRow key={subtask.id}>
                      <TableCell
                        sx={{ width: "2px", backgroundColor: "yellow" }}
                      ></TableCell>
                      <TableCell
                        sx={{ minWidth: 100, backgroundColor: "green" }}
                      >
                        {subtask.title}
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: 200, backgroundColor: "blue" }}
                      >
                        {subtask.owner}
                      </TableCell>
                      <TableCell
                        sx={{ maxWidth: 80, backgroundColor: "pink" }}
                      >
                        {/* Additional content for subtask cell if needed */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            ))}
          </React.Fragment>
        ))}
        {/* </TableBody>
        </Table> */}
      </TableContainer>
    </>
  );
};

export default App;

{
  /* <TableContainer component={Paper} sx={{ marginTop: 2 }}>
<Table>
  <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
  <TableBody>
    {tasks.map((task) => (
      <React.Fragment key={task.id}>
        <TableRow>
          <TableCell>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {task.title}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Subtask Title</TableCell>
                        <TableCell>Subtask Owner</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {task.subtasks.map((subtask) => (
                        <TableRow key={subtask.id}>
                          <TableCell>{subtask.title}</TableCell>
                          <TableCell>{subtask.owner}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </TableCell>
          <TableCell>{task.owner}</TableCell>
          <TableCell>
            <Button variant="outlined" onClick={() => editTask(task)}>
              Edit Task
            </Button>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ))}
  </TableBody>
</Table>
</TableContainer> */
}
