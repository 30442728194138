import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { mainMainItems, mainMainItemsAdmin } from "../common/MainItems";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useLocation } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";
import {
  Authenticator,
  View,
  Image,
  useTheme,
  Text,
} from "@aws-amplify/ui-react";

import { signOut } from "aws-amplify/auth";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function MainAppBar() {
  const currentYear = new Date().getFullYear();

  var pathName = useLocation().pathname;

  // // Remove all occurrences of the specified substring
  pathName = pathName.slice(1);

  // // Convert the resulting string to uppercase
  pathName = pathName.toUpperCase();

  // const handleLogout = () => {
  //   signOut();
  // };

  const [userAuth, setUserAuth] = useState("");

  useEffect(() => {
    async function fetchUser() {
      try {
        const currentUser = await fetchUserAttributes(); // Make sure fetchUserAttributes is defined/imported
        setUserAuth(currentUser);
      } catch (err) {
        console.log(err.message);
      }
    }
    fetchUser();
  }, [userAuth]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={tokens.space.large}
        >
          <IconButton href="/" sx={{ p: 0 }}>
            <Avatar
              variant="square"
              alt="logo"
              sx={{ m: 1, width: 200, height: 100 }}
              src="img/excelsiorLogo.png"
            />
          </IconButton>
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; {currentYear}. All rights reserved
          </Text>
        </View>
      );
    },
    SignIn: {
      Footer() {
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              href="/"
              size="small"
              variation="link"
            >
              Cancel
            </Button>
          </View>
        );
      },
    },
  
  };


  return (
    <Authenticator components={components} hideSignUp={true}>
      <>
        {userAuth.profile !== null ? (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ background: "#125570" }}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" color="white">
                    {pathName}
                  </Typography>
                </Box>

                <Box sx={{ flexGrow: 0, display: { md: "flex" } }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Typography
                        variant="h5"
                        marginRight={1}
                        textAlign={"right"}
                        fontSize={8}
                        color="#F4FDFF"
                      >
                        Bem-vindo <br />@ {userAuth.given_name}{" "}
                        {userAuth.family_name}
                      </Typography>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar alt="Avatar" src="img/excelsiorLogoSmall.png" />
                      </StyledBadge>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {/* <MenuItem key='profile'>
            <Link to={'/profile'} textAlign="center">Profile</Link>
            </MenuItem> */}
                    <MenuItem key="Logout">
                      <Typography
                        component="a"
                        textAlign="center"
                        onClick={() => signOut()}
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 6,
                    display: { xs: "flex", md: "flex" },
                    //   fontFamily: 'monospace',
                    fontWeight: 600,
                    fontSize: "1rem",
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {userAuth.name}
                </Typography>

                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              {/* {user.attributes.profile === "Admin" && ( */}
              <List>
                {mainMainItemsAdmin.map((item, index) => (
                  <Tooltip title={item.label} placement="right">
                    <ListItem key={index} onClick={() => navigate(item.route)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
              {/* // )} */}
              {/* {user.attributes.profile !== "Admin" && (
              <List>
                {mainMainItems.map((item, index) => (
                  <Tooltip title={item.label} placement="right">
                    <ListItem key={index} onClick={() => navigate(item.route)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            )} */}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />

              <Outlet />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    </Authenticator>
  );
}

export default MainAppBar;
