import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chart from 'react-apexcharts';

const TeamOverview = () => {
  const tasks = [
    { task: 'Team A', completed: 5, inProgress: 3, notStarted: 2 },
    { task: 'Team B', completed: 8, inProgress: 2, notStarted: 1 },
    // Add more tasks as needed
  ];

  const chartData = {
    options: {
      chart: {
        id: 'tasks-chart',
        stacked: true,
        type: 'bar',
        toolbar: {
          show: false,
        },
        width: '50%', // Adjust the width of the chart as needed
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: tasks.map((task) => task.task),
        labels: {
          show: true, // Show or hide labels on the x-axis
        },
      },
      yaxis: {
        title: {
          text: 'Tasks',
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
      }
    },
    series: [
      {
        name: 'Completed',
        data: tasks.map((task) => task.completed),
      },
      {
        name: 'In Progress',
        data: tasks.map((task) => task.inProgress),
      },
      {
        name: 'Not Started',
        data: tasks.map((task) => task.notStarted),
      },
    ],
  };

  return (
    <Box>
      <Typography variant="h5"  align="center" gutterBottom>
        Team Overview 
      </Typography>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />
    </Box>
  );
};

export default TeamOverview;
