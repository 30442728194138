import React from "react";
import { Container, Grid } from "@mui/material";
import QMSCard from "./QMSCard";

const QMSApp = () => {
  const cardData = [
    {
      title: "INMETRO",
      logo: "/img/inmetro.png",
      status: "100%",
      description: "Sistema de gestão INMETRO",
      actions: [
        { label: "Acessar", onClick: () => console.log("Acessar") },
        // { label: 'Edit', onClick: () => console.log('Edit Task 1') },
      ],
    },
    {
      title: "ISO17025",
      logo: "/img/iso17025.png",
      description: "Sistema de gestão ISO17025",
      status: "100%",
      actions: [
        { label: "Acessar", path: "/draft" },
        // { label: 'Edit', onClick: () => console.log('Edit Task 2') },
      ],
    },
    {
      title: "ISO9001",
      logo: "/img/iso9001.png",
      description: "Sistema de gestão ISO9001",
      status: "100%",
      actions: [
        { label: "Acessar", onClick: () => console.log("Acessar") },
        // { label: 'Edit', onClick: () => console.log('Edit Task 2') },
      ],
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        {cardData.map((card, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <QMSCard
              title={card.title}
              logo={card.logo}
              status={card.status}
              description={card.description}
              actions={card.actions}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default QMSApp;
