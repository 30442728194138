import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "react-bootstrap/Form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DialogContentText from "@mui/material/DialogContentText";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

import { v4 as uuid } from "uuid";
import { generateClient } from "aws-amplify/api";
import { createTask } from "../../graphql/mutations";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
Amplify.configure(amplifyconfig);

const client = generateClient();

export default function TaskDialog() {
  const userId = "tvasconcelos";

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const currentDate = new Date().toLocaleString();

  const [pipelineData, setPipelineData] = useState({
    taskId: uuid(),
    owner: `${userId}`,
    title: "",
    description: "",
    dueDate: "",
    priority: "",
    status: "",
  });


  const addNewPipeline = async (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === true) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    setValidated(true);

    const { taskId, owner, title, description, dueDate, priority, status } =
      pipelineData;

    const newPipeline = {
      taskId,
      owner,
      title,
      description,
      dueDate,
      priority,
      status,
    };

    // Persist new Pipeline
    await client.graphql({
      query: createTask,
      variables: { input: newPipeline },
    });

    handleClose();
 
    // window.location.reload(true);
  };

  const [validated, setValidated] = useState(false);

  return (
    <React.Fragment>
      <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      {/* <Button variant="contained" startIcon={<AddCircleIcon/>} onClick={handleClickOpen}>
        Task
      </Button> */}
      <Dialog
        // fullWidth={true}
        // fullScreen
        // maxWidth='sm'
        // open={open}
        // onClose={handleClose}
        open={open}
        onClose={handleClose}
        scroll="body"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Opportunity Name"
                  required
                  value={pipelineData.title}
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      title: evt.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  required
                  value={pipelineData.description}
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      description: evt.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Due Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Due Date"
                  required
                  value={pipelineData.dueDate}
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      dueDate: evt.target.value,
                    })
                  }
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Status"
                  required
                  value={pipelineData.status}
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      status: evt.target.value,
                    })
                  }
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      status: evt.target.value,
                    })
                  }
                >
                  <option>
                    {pipelineData.status === ""
                      ? "---Select Status---"
                      : pipelineData.status}
                  </option>
                  <option value="TODO">TODO</option>
                  <option value="IN_PROGRESS">IN_PROGRESS</option>
                  <option value="COMPLETED">COMPLETED</option>
                </Form.Select>
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Priority"
                  required
                  value={pipelineData.priority}
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      priority: evt.target.value,
                    })
                  }
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Priority</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(evt) =>
                    setPipelineData({
                      ...pipelineData,
                      priority: evt.target.value,
                    })
                  }
                >
                  <option>
                    {pipelineData.priority === ""
                      ? "---Select Priority---"
                      : pipelineData.priority}
                  </option>
                  <option value="1">1--Low</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5--High</option>
                </Form.Select>
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Products</Form.Label>
                <Form.Control type="text" placeholder="Products"
                  required
                  value={pipelineData.oppProducts} 
                  onChange={evt => setPipelineData({...pipelineData, oppProducts:evt.target.value})} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Stage</Form.Label>
                <Form.Select aria-label="Default select example"
                  onChange={evt => setPipelineData({...pipelineData, oppStage:evt.target.value})}
                >
                  <option>{pipelineData.oppStage === "" ? "---Select Stage---" : pipelineData.oppStage }</option>
                  <option value="Commit">Commit</option>
                  <option value="Commit at Risk">Commit at Risk</option>
                  <option value="Upside">Upside</option>
                  <option value="Closed Won">Closed Won</option>
                  <option value="Closed Lost">Closed Lost</option>
                  <option value="Canceled">Canceled</option>
                </Form.Select>
            </Form.Group> 
            <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Amount($)</Form.Label>
                <Form.Control type="text" placeholder="Amount($)" 
                  required
                  value={pipelineData.oppAmount} 
                  onChange={evt => setPipelineData({...pipelineData, oppAmount:evt.target.value})} />
            </Form.Group> */}
            </Form>
            {/* <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Industry</Form.Label>
                <Form.Select aria-label="Default select example"
                  onChange={evt => setPipelineData({...pipelineData, oppIndustry:evt.target.value})}
                >
                  <option>{pipelineData.oppIndustry === "" ? "---Select Industry---" : pipelineData.oppIndustry }</option>
                  <option value="Aerospace">Aerospace</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Food & Beverage">Food & Beverage</option> 
                  <option value="Mining">Mining</option>
                  <option value="Power Generation">Power Generation</option>
                  <option value="Pharmaceutics">Pharmaceutics</option>
                  <option value="Pulp & Paper">Pulp & Paper</option>
                  <option value="Downstream O&G">Downstream O&G</option>
                  <option value="Midstream O&G">Midstream O&G</option>
                  <option value="Upstream O&G">Upstream O&G</option>
                </Form.Select>
            </Form.Group> 
            <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Label>Comments</Form.Label>
                <Form.Control type="text" placeholder="Comments"
                  required
                  value={pipelineData.oppComments} 
                  onChange={evt => setPipelineData({...pipelineData, oppComments:evt.target.value})} />
            </Form.Group> */}
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={addNewPipeline}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
