import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TaskDialog from "./TaskDialog";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";

import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
Amplify.configure(amplifyconfig);

const client = generateClient();

function TaskCard() {
  const [tasks, setTasks] = useState([]);
    

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    try {
      const tasksData = await client.graphql({ query: queries.listTasks });
      const tasksList = tasksData.data.listTasks.items;
      setTasks(tasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleUpdatePriority = async (taskId, newPriority) => {
    // console.log("taskId:", taskId);
    // console.log("newPriority:", newPriority);
  };

  return (
    <>
      <TaskDialog />
      <br></br>
      <br></br>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        {tasks.map((task) => (
          <Grid item xs={2} sm={4} md={4}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 300,
                // maxWidth: 580,
              }}
            >
              <CardHeader
                title={task.title}
                subheader={"Deadline: " + `${task.dueDate}`}
              />

              <CardContent>
                <Typography variant="body2" component="p">
                  {task.description}
                </Typography>
              </CardContent>

              <CardActions>
                <Rating
                  id={task.taskId}
                  name="simple-controlled"
                  value={task.priority}
                  onChange={handleUpdatePriority}
                />
              </CardActions>
              <CardActions>
                <Chip
                  color={task.status === "TODO" ? "warning" : task.status === "COMPLETED" ? "success" :  "info" } 
                  label={task.status}
                  variant="outlined"
                />
                <Chip color="primary" label={task.owner} />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default TaskCard;
