import React from "react";
import {
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ErrorPage = () => {
  const useStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  };

  return (
    <Container>
      <AppBar position="fixed" width="100%" sx={{ background: "#125570" }}>
        <Toolbar>
        <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              EXCELSIOR
            </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={useStyles}>
        <Typography variant="h1">Oops!</Typography>
        <Typography variant="body1">
          Página não encontrada.
        </Typography>
        <Typography variant="body1" component="i">
        </Typography>
      </Box>
    </Container>
  );
};

export default ErrorPage;
