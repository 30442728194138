import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const reloadAndNavigate = () => {
      window.location.reload();
      localStorage.setItem("navigateAfterReload", "/dashboard");
    };

    const navigateAfterReload = localStorage.getItem("navigateAfterReload");
    if (navigateAfterReload) {
      localStorage.removeItem("navigateAfterReload");
      navigate(navigateAfterReload);
    } else {
      const timer = setTimeout(reloadAndNavigate, 100);
      return () => clearTimeout(timer);
    }
  }, [navigate]);

  return <div>Redirecting...</div>; // Or any loading indicator
}

export default Login;
