export const tasksInput = [
    {
        id: 1, 
        title: "Task 1", 
        description: "Description 1",
        owner: "TVasconcelos",
        status: "Ongoing", 
        progress: 70,
        deadline: "18/02/2024", 
        priority: 2,
        projectName: "Project 1", 
    },
    {
        id: 2, 
        title: "Task 2", 
        description: "Description 2",
        owner: "TVilela",
        status: "Delayed", 
        progress: 30,
        deadline: "18-April-2024", 
        priority: 1,
        projectName: "Project 1", 
    },
    {
        id: 3, 
        title: "Task 3", 
        description: "Description 3",
        owner: "TWilliams",
        status: "Completed", 
        progress: 100,
        deadline: "18-April-2024", 
        priority:3,
        projectName: "Project 2", 
  },
]

export const projectsInput = [
    {
        id: 1, 
        name: "Project 1", 

    },
    {
        id: 2, 
        name: "Project 2", 

    },
    {
      id: 3, 
      name: "Project 3", 
  },
]

export const subtasksInput = [
    {
        id: 1, 
        description: "SubTask 1.1", 
        status: "Not Started", 
        owner: "TVasconcelos",
        completed: "False",
        completedDate: "", 
        taskName: "Task 1", 
    },
    {
        id: 2, 
        description: "SubTask 1.2", 
        status: "Not Started", 
        owner: "TWilliams",
        completed: "False",
        completedDate: "", 
        taskName: "Task 1", 
    },
    {
        id: 3, 
        description: "SubTask 2.1", 
        status: "Completed", 
        owner: "TWilliams",
        completed: "True",
        completedDate: "18/02/2024", 
        taskName: "Task 2", 
    },
    {
        id: 4, 
        description: "SubTask 3.1", 
        status: "Completed", 
        owner: "TWilliams",
        completed: "True",
        completedDate: "18/02/2024", 
        taskName: "Task 3", 
    },
    {
        id: 5, 
        description: "SubTask 3.2", 
        status: "Ongoing", 
        owner: "TWilliams",
        completed: "True",
        completedDate: "18/03/2024", 
        taskName: "Task 3", 
    },


]