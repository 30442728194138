import GridViewIcon from '@mui/icons-material/GridView';
import PeopleIcon from '@mui/icons-material/People';
import ImageIcon from '@mui/icons-material/Image';
import PublicIcon from '@mui/icons-material/Public';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import DnsIcon from '@mui/icons-material/Dns';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import HubIcon from '@mui/icons-material/Hub';
import HelpIcon from '@mui/icons-material/Help';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SchoolIcon from '@mui/icons-material/School';
import ContactsIcon from '@mui/icons-material/Contacts';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedIcon from '@mui/icons-material/Verified';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from '@mui/icons-material/Home';
import PolicyIcon from '@mui/icons-material/Policy';

export const mainMainItems = [
    // {
    //     id: 0,
    //     icon: <GridViewIcon />,
    //     label: 'Dashboard',
    //     route: 'dashboard',
    // },
    {
        id: 17,
        icon: <HomeIcon />,
        label: 'Home Page',
        route: '/',
    },
    // {
    //     id: 1,
    //     icon: <DnsIcon />,
    //     label: 'Assets',
    //     route: 'assets',
    // },
    // {
    //     id: 2,
    //     icon: <PeopleIcon />,
    //     label: 'Customers',
    //     route: 'customers',
    // },
    // {
    //     id: 11,
    //     icon: <ContactsIcon />,
    //     label: 'Contacts',
    //     route: 'contacts',
    // },
    // {
    //     id: 3,
    //     icon: <DescriptionIcon />,
    //     label: 'Certificates',
    //     route: 'certificates',
    // },
    // {
    //     id: 4,
    //     icon: <SchoolIcon />,
    //     label: 'LMS',
    //     route: 'lms',
    // },
    {
        id: 5,
        icon: <VerifiedIcon />,
        label: 'QMS',
        route: 'qms',
    },
    // {
    //     id: 6,
    //     icon: <ShoppingBagIcon />,
    //     label: 'Price List',
    //     route: 'pricelist',
    // },
    // {
    //     id: 7,
    //     icon: <LeaderboardIcon />,
    //     label: 'Pipeline',
    //     route: 'pipeline',
    // },
    {
        id: 8,
        icon: <HubIcon />,
        label: 'InTouch',
        route: 'intouch',
    },
    // {
    //     id: 9,
    //     icon: <SettingsInputComponentIcon />,
    //     label: 'MachineLearning',
    //     route: 'machinelearning',
    // },
    // {
    //     id: 10,
    //     icon: <HelpIcon />,
    //     label: 'Help',
    //     route: 'handbook',
    // },
    // {
    //     id: 12,
    //     icon: <AdminPanelSettingsIcon />,
    //     label: 'Admin',
    //     route: 'admin',
    // },
    // {
    //     id: 13,
    //     icon: <EngineeringIcon />,
    //     label: 'Draft',
    //     route: 'draft',
    // },

]

export const mainMainItemsAdmin = [
    {
        id: 17,
        icon: <HomeIcon />,
        label: 'Home Page',
        route: '/',
    },
    {
        id: 0,
        icon: <GridViewIcon />,
        label: 'Visão Geral',
        route: 'dashboard',
    },
    // {
    //     id: 1,
    //     icon: <DnsIcon />,
    //     label: 'Assets',
    //     route: 'assets',
    // },
    // {
    //     id: 2,
    //     icon: <PeopleIcon />,
    //     label: 'Customers',
    //     route: 'customers',
    // },
    // {
    //     id: 11,
    //     icon: <ContactsIcon />,
    //     label: 'Contacts',
    //     route: 'contacts',
    // },
    // {
    //     id: 3,
    //     icon: <DescriptionIcon />,
    //     label: 'Certificates',
    //     route: 'certificates',
    // },
    // {
    //     id: 4,
    //     icon: <SchoolIcon />,
    //     label: 'LMS',
    //     route: 'lms',
    // },
    {
        id: 5,
        icon: <VerifiedIcon />,
        label: 'Sistema da Qualidade',
        route: 'qms',
    },
    {
        id: 14,
        icon: <ViewTimelineIcon />,
        label: 'Planejamento',
        route: 'planejamento',
    },
    {
        id: 8,
        icon: <HubIcon />,
        label: 'InTouch',
        route: 'intouch',
    },
    {
        id: 15,
        icon: <EventIcon />,
        label: 'Eventos',
        route: 'eventos',
    },
    // {
    //     id: 6,
    //     icon: <ShoppingBagIcon />,
    //     label: 'Price List',
    //     route: 'pricelist',
    // },
    // {
    //     id: 7,
    //     icon: <LeaderboardIcon />,
    //     label: 'Pipeline',
    //     route: 'pipeline',
    // },

    // {
    //     id: 9,
    //     icon: <SettingsInputComponentIcon />,
    //     label: 'MachineLearning',
    //     route: 'machinelearning',
    // },
    // {
    //     id: 10,
    //     icon: <HelpIcon />,
    //     label: 'Help',
    //     route: 'handbook',
    // },
    // {
    //     id: 12,
    //     icon: <AdminPanelSettingsIcon />,
    //     label: 'Admin',
    //     route: 'admin',
    // },
    // {
    //     id: 13,
    //     icon: <EngineeringIcon />,
    //     label: 'Draft',
    //     route: 'draft',
    // },
    {
        id: 16,
        icon: <PolicyIcon />,
        label: 'Auditorias',
        route: 'auditorias',
    }
]