import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "@aws-amplify/ui-react/styles.css";
import { getCurrentUser } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

const pages = ["QUEM SOMOS", "CONTATOS"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const HomeExcelsior = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    async function currentAuthenticatedUser() {
      try {
        const { userId } = await getCurrentUser();
        setUserId(userId);
      } catch (err) {
        console.log(err.message);
      }
    }
    currentAuthenticatedUser();
  }, []);

  // Function to handle sign-out
  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.reload();
      console.log("User signed out successfully");
      // Redirect to sign-in page or perform any other actions after sign-out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div>
      <AppBar position="static" sx={{ background: "#125570" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              EXCELSIOR
            </Typography>
            {/* Cellphone layout*/}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {/* <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem key="pressureConverter" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" component={Link} Link to="/conversor">
                    Conversor de Pressão
                  </Typography>
                </MenuItem>
              </Menu> */}
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              EXCELSIOR
            </Typography>
            {/* Normal Page */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* <Typography
                // variant="p"
                noWrap
                component={Link}
                Link to="/conversor"
                sx={{
                  flexGrow: 1,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Conversor de Pressão
              </Typography> */}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Abrir Opções">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="logo" src="/img/excelsiorLogoSmall.png" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                { userId ?
                  <MenuItem key="Login">
                  <Typography
                    textAlign="center"
                    component={Link}
                    Link to="Login"
                  >
                    Login
                  </Typography>
                </MenuItem>
                :
                <MenuItem key="Login">
                  <Typography
                    textAlign="center"
                    component={Link}
                    Link to="Dashboard"
                  >
                    MyApp
                  </Typography>
                </MenuItem>

                }
                {/* <MenuItem key="Login">
                  <Typography
                    textAlign="center"
                    component={Link}
                    Link to={userId ? "Dashboard" : "Login"}
                  >
                    {userId ? "MyApp" : "Login"}
                  </Typography>
                </MenuItem> */}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Container>
        <Outlet />
      </Container>
      {/* Foot Box */}
      <Footer />
    </div>
  );
};

export default HomeExcelsior;
