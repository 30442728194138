import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

const QMSCard = ({ title, logo, status, description, actions }) => {
  const navigate = useNavigate();

  const handleActionClick = (action) => {
    navigate(action); // Navigate to the specified path

  };

  return (
    <>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar
              alt="avatar"
              variant="square"
              src={logo}
              sx={{ width: 40, height: 40 }}
            />
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={title}
          subheader="September 14, 2016"
        />
        <hr></hr>
        <CardContent sx={{ height: "100px" }}>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(18,85,112,1)",
          }}
        >
          {actions.map((action, index) => (
            <Button
              sx={{ color: "#ffffff" }}
              key={index}
              onClick={() => handleActionClick(action.path)}
            >
              {action.label}
            </Button>
          ))}
        </CardActions>
      </Card>

      {/* <Card sx={{ maxWidth: 345, margin: "1rem" }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status: {status}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          {actions.map((action, index) => (
            <Button key={index} size="small" onClick={action.onClick}>
              {action.label}
            </Button>
          ))}
        </CardActions>
      </Card> */}
    </>
  );
};

export default QMSCard;
