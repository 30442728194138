import React, { useState } from "react";
import { TextField, Button, Container, Typography, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const TaskForm = ({ onSubmit }) => {
  const [task, setTask] = useState({ title: '', owner: '', subtasks: [] });
  const [newSubTask, setNewSubTask] = useState({ title: '', owner: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(task);
    setTask({ title: '', owner: '', subtasks: [] });
    setNewSubTask({ title: '', owner: '' });
  };

  const handleAddSubTask = () => {
    setTask({
      ...task,
      subtasks: [...task.subtasks, { ...newSubTask }]
    });
    setNewSubTask({ title: '', owner: '' });
  };

  console.log(task)
  console.log(newSubTask)

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoFocus
          value={task.title}
          onChange={(e) => setTask({ ...task, title: e.target.value })}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="owner"
          label="Owner"
          name="owner"
          value={task.owner}
          onChange={(e) => setTask({ ...task, owner: e.target.value })}
        />
        <Typography variant="h6">Subtasks:</Typography>
        {task.subtasks.map((subtask, index) => (
          <div key={index}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={`Subtask ${index + 1} Title`}
              value={subtask.title}
              onChange={(e) => {
                const updatedSubtasks = [...task.subtasks];
                updatedSubtasks[index].title = e.target.value;
                setTask({ ...task, subtasks: updatedSubtasks });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={`Subtask ${index + 1} Owner`}
              value={subtask.owner}
              onChange={(e) => {
                const updatedSubtasks = [...task.subtasks];
                updatedSubtasks[index].owner = e.target.value;
                setTask({ ...task, subtasks: updatedSubtasks });
              }}
            />
          </div>
        ))}
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="New Subtask Title"
            value={newSubTask.title}
            onChange={(e) => setNewSubTask({ ...newSubTask, title: e.target.value })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="New Subtask Owner"
            value={newSubTask.owner}
            onChange={(e) => setNewSubTask({ ...newSubTask, owner: e.target.value })}
          />
          <IconButton onClick={handleAddSubTask} color="primary">
            <AddIcon />
          </IconButton>
        </div>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Create Task
        </Button>
      </form>
    </Container>
  );
};

export default TaskForm;
