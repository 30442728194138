import React, { useState, useEffect } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid";
import styled from "@mui/system/styled";
import Row from "react-bootstrap/Row";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";


const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "left",
}));

export default function Audits(props) {
  return (
    <Container maxWidth="xlg">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                bgcolor: "rgba(18, 85, 112, 0.4)",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                minHeight: 180,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                INMETRO
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                12
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                Última: 10-Feb-2024 | Próxima: 20-Abril-2024
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Box 
              sx={{
                bgcolor: "rgba(18, 85, 112, 0.4)",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 180,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                ISO 9001
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                7
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ marginTop: 2,  opacity: 0.7 }}
              >
                Última: 10-Feb-2024 | Próxima: 20-Abril-2024
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Box
              sx={{
                bgcolor: "rgba(18, 85, 112, 0.4)",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                height: 180,
                maxWidth: 580,
              }}
            >
              <Typography variant="h5" align="center">
                ISO 17025
              </Typography>
              <Typography
                variant="h3"
                align="center"
                sx={{ marginTop: 2, opacity: 0.7 }}
              >
                3
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ marginTop: 2,  opacity: 0.7 }}
              >
                Última: 10-Feb-2024 | Próxima: 20-Abril-2024
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        {/* <Row className='px-2'>
					<h1>{userProfile} Dashboard</h1>
				</Row> */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >

     
        </Grid>
      </Box>
    </Container>
  );
}
