import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import Stack from "@mui/material/Stack";
import { getColorList, getRandomHexColor } from "./getColorList";
import { compareAsc, format } from "date-fns";

import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
Amplify.configure(amplifyconfig);
const client = generateClient();

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ backgroundColor: "#FFA500" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody> */}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable() {
  const [open, setOpen] = React.useState(false);

  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    getTasks();
  }, []);

  const [subTasks, setSubTasks] = useState([]);
  useEffect(() => {
    getSubTasks();
  }, []);

  const getTasks = async () => {
    try {
      const tasksData = await client.graphql({ query: queries.listTasks });
      const tasksList = tasksData.data.listTasks.items;
      setTasks(tasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getSubTasks = async () => {
    try {
      const subTasksData = await client.graphql({
        query: queries.listSubtasks,
      });
      const subTasksList = subTasksData.data.listSubtasks.items;
      setSubTasks(subTasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const randomColor = getRandomHexColor();

  const color = getColorList();

  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = () => {
    const sortedData = [...tasks].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.title < b.title ? -1 : 1;
      } else {
        return a.title > b.title ? -1 : 1;
      }
    });

    setTasks(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          // variant="outlined"
          // color="primary"
          sx={{
            backgroundColor: "#ECECEC ",
            color: "black",
            borderRadius: "10px",
          }}
          onClick={handleSort}
          startIcon={
            sortOrder === "asc" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
        >
          Sort by title
        </Button>
        <Button
          // variant="outlined"
          // color="primary"
          sx={{
            backgroundColor: "#ECECEC ",
            color: "black",
            borderRadius: "10px",
          }}
          startIcon={<FilterListIcon />}
        >
          Filter
        </Button>
      </Stack>
      <br></br>
      <TableContainer>
        {/* <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell >
              <Typography sx={{ fontSize: 12 }} color="#FFA500" gutterBottom>
                Serviços Acompanhado Durante a Auditoria
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="#FFA500" gutterBottom>
                {tasks.length} Tarefas
              </Typography>
            </TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
      <br></br> */}
        {tasks.map((task, index) => (
          <>
            <Table size="small" aria-label="collapsible table">
              <TableBody>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell
                  // sx={{
                  //   backgroundColor: color[index].hex,
                  //   width: 60,
                  //   borderRadius: "50px",
                  // }}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      sx={{ backgroundColor: color[index].hex }}
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ minWidth: 450, color: color[index].hex }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography variant="body1" gutterBottom>
                        {task.title.toUpperCase()}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        color={color[index].hex}
                        gutterBottom
                      >
                        {tasks.length} Tarefas
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: 100 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography
                        variant="body1"
                        color={color[index].hex}
                        gutterBottom
                      >
                        Owner:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        color={color[index].hex}
                        gutterBottom
                      >
                        {task.owner}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: 100 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography
                        variant="body1"
                        color={color[index].hex}
                        gutterBottom
                      >
                        Due Date:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        color={color[index].hex}
                        gutterBottom
                      >
                        {format(new Date(task.dueDate), "dd-MM-yyyy")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: 100 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography
                        variant="body1"
                        color={color[index].hex}
                        gutterBottom
                      >
                        Last Update:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        color={color[index].hex}
                        gutterBottom
                      >
                        {format(new Date(task.updatedAt), "dd-MM-yyyy")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: 100 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography
                        variant="body1"
                        color={color[index].hex}
                        gutterBottom
                      >
                        Priority:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12 }}
                        color={color[index].hex}
                        gutterBottom
                      >
                        {task.priority}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={12}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Sub Tasks
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 400 }}>
                            Description
                          </TableCell>
                          <TableCell sx={{ minWidth: 100 }}>Date</TableCell>
                          <TableCell sx={{ minWidth: 100 }}>Customer</TableCell>
                          <TableCell align="right" sx={{ minWidth: 100 }}>
                            Amount
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: 100 }}>
                            Total price ($)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Description
                          </TableCell>
                          <TableCell>01/01/2025</TableCell>
                          <TableCell align="right">Customer</TableCell>
                          <TableCell align="right">200</TableCell>
                          <TableCell align="right">400</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Description
                          </TableCell>
                          <TableCell>01/01/2025</TableCell>
                          <TableCell align="right">Customer</TableCell>
                          <TableCell align="right">200</TableCell>
                          <TableCell align="right">400</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <br></br>
          </>
        ))}
      </TableContainer>
    </>
  );
}
