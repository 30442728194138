import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import MainAppBar from "./common/MainAppBarDark";
import EventPage from "./pages/Events/EventPage";
import ProjectApp from "./pages/Project/ProjectApp";
import { fetchUserAttributes } from "aws-amplify/auth";
import PressureConverter from "./pages/Converter/PressureConverter";
import MainAppBarExcelsior from "./common/MainAppBarExcelsior";
import HomePage from "./pages/HomePage/HomePage";
import Qms from "./pages/Qms/Qms";
import Login from "./pages/SignIn/LogIn";
import Audits from "./pages/Audits/Audits";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Draft from "./pages/Draft/Draft";

import { Amplify } from "aws-amplify";
import amplifyconfig from "./aws-exports";
Amplify.configure(amplifyconfig);

export default function App() {
  const [userAuth, setUserAuth] = useState("");

  useEffect(() => {
    async function fetchUser() {
      try {
        const currentUser = await fetchUserAttributes();
        setUserAuth(currentUser);
      } catch (err) {
        console.log(err.message);
      }
    }
    fetchUser();
  }, []);

  return (
    <React.StrictMode>
      <Routes errorElement={<ErrorPage />}>
        <Route
          path="/"
          exact
          element={<MainAppBarExcelsior userAuth />}
          errorElement={<ErrorPage />}
        >
          <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
          <Route
            path="conversor"
            element={<PressureConverter />}
            errorElement={<ErrorPage />}
          />
        </Route>

        <Route
          path="/"
          exact
          element={<MainAppBar />}
          errorElement={<ErrorPage />}
        >
          <Route
            path="login"
            element={<Login />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="dashboard"
            element={<Dashboard />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="planejamento"
            element={<ProjectApp />}
            errorElement={<ErrorPage />}
          />
          <Route path="qms" element={<Qms />} errorElement={<ErrorPage />} />
          <Route path="/auditorias" element={<Audits />} />
          <Route
            path="eventos"
            element={<EventPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/draft"
            element={<Draft />}
            errorElement={<ErrorPage />}
          />
        </Route>
        <Route path="/*" element={<ErrorPage />} errorElement={<ErrorPage />} />
      </Routes>
    </React.StrictMode>
  );
}
