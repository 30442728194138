/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const createSubtask = /* GraphQL */ `
  mutation CreateSubtask(
    $input: CreateSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    createSubtask(input: $input, condition: $condition) {
      subtaskId
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const updateSubtask = /* GraphQL */ `
  mutation UpdateSubtask(
    $input: UpdateSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    updateSubtask(input: $input, condition: $condition) {
      subtaskId
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const deleteSubtask = /* GraphQL */ `
  mutation DeleteSubtask(
    $input: DeleteSubtaskInput!
    $condition: ModelSubtaskConditionInput
  ) {
    deleteSubtask(input: $input, condition: $condition) {
      subtaskId
      taskId
      owner
      title
      description
      dueDate
      priority
      status
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
