import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function Footer() {
  return (
    <Container
      maxWidth="100%"
      sx={{ backgroundColor: "#125570", minHeight:250 }}
    >
      <Box
        sx={{
          color: "#ffffff",
          p: 2,
          marginTop: 4,
        }}
      >
        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
          Entre em contato conosco:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">
                <WhatsAppIcon sx={{ marginRight: 1 }} />
                (11) 99946-1828 / (11) 97130-1709 
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">
                <EmailIcon sx={{ marginRight: 1 }} />
                atendimento@excelsiormetrologia.com.br 
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">
                <EmailIcon sx={{ marginRight: 1 }} />
                daiana.carla@excelsiormetrologia.com.br 
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">
                <EmailIcon sx={{ marginRight: 1 }} />
                felipe.reis@excelsiormetrologia.com.br 
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <footer>
          <Typography align="center" mt={3} variant="body2">
            © {new Date().getFullYear()}. Todos os direitos reservados.
          </Typography>
        </footer>
      </Box>
    </Container>
  );
}

export default Footer;
