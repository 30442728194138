export const getColorList = () => {
    const colors = [
      {
        "name": "Coral",
        "hex": "#FF6F61"
      },
      {
        "name": "Sky Blue",
        "hex": "#87CEEB"
      },
      {
        "name": "Goldenrod",
        "hex": "#DAA520"
      },
      {
        "name": "Lavender",
        "hex": "#E6E6FA"
      },
      {
        "name": "Mint Green",
        "hex": "#98FF98"
      },
      {
        "name": "Slate Gray",
        "hex": "#708090"
      },
      {
        "name": "Salmon",
        "hex": "#FA8072"
      },
      {
        "name": "Peru",
        "hex": "#CD853F"
      },
      {
        "name": "Deep Sky Blue",
        "hex": "#00BFFF"
      },
      {
        "name": "Orchid",
        "hex": "#DA70D6"
      }
    ];
  
    return colors;
  };

  export const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
  
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  
    return color;
  };