import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import ProjectPanel from "./ProjectPanel";
import TaskApp from "./TaskApp";

import TaskDialog from "./TaskDialog";

import { generateClient } from 'aws-amplify/api';

import { listTasks } from '../../graphql/queries';
import * as queries from '../../graphql/queries';

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
Amplify.configure(amplifyconfig);


const client = generateClient();



const ProgressApp = () => {

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    getTasks()
  }, []);

  const getTasks = async() => {
    try {
        const tasksData = await client.graphql({ query: queries.listTasks });
        const tasksList = tasksData.data.listTasks.items;
          setTasks(tasksList);

    } catch(err) {
        console.log('error', err);
    }
  } 

  return (
    <Container maxWidth="xlg">
      {/* <TaskDialog /> */}
      {/* <TaskApp title="Example Task" description="This is a sample task description." /> */}
      <ProjectPanel />
    </Container>
  );
};

export default ProgressApp;
