import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import TableSortLabel from "@mui/material/TableSortLabel";
import Chip from "@mui/material/Chip";

import TaskProgress from "./TaskProgress";
import TaskUpdate from "./TaskUpdate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Rating from "@mui/material/Rating";

import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
import { ConsoleLogger } from "aws-amplify/utils";
Amplify.configure(amplifyconfig);
const client = generateClient();

const headCells = [
  { id: "blank", label: "" },
  { id: "title", label: "Task" },
  { id: "owner", label: "Owner" },
  { id: "status", label: "Status" },
  // { id: "progress", label: "Progress" },
  { id: "deadline", label: "Deadline" },
  { id: "priority", label: "Priority" },
  { id: "action", label: "" },
];

export default function CollapsibleTable({
  tasksTable,
  subtasks,
  projects,
  onDelete,
  onEdit,
  optionsStatus,
  optionsPriority,
  optionsOwner,
}) {
  const currentDate = new Date();
  const [value, setValue] = useState(2);

  const [tasks, setTasks] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    try {
      const tasksData = await client.graphql({ query: queries.listTasks });
      const tasksList = tasksData.data.listTasks.items;
      setTasks(tasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const [orderBy, setOrderBy] = useState("status");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...tasks].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                align="center"
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedData.map((row) => (
            <>
              <TableRow
                key={row.id}
                sx={{ "& > *": { borderBottom: "unset" } }}
              >
                <TableCell align="center">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <KeyboardDoubleArrowUpIcon />
                    ) : (
                      <KeyboardDoubleArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">
                  <Chip label={row.owner} color="primary" variant="outlined" />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={row.status}
                    sx={{
                      backgroundColor:
                        row.status == "TODO"
                          ? " #FFCCCB "
                          : row.status == "IN_PROGRESS"
                          ? "#FFD580"
                          : row.status == "COMPLETED"
                          ? "lightGreen"
                          : "White",
                    }}
                  />
                </TableCell>
                {/* <TableCell align="center">
                  {" "}
                  <TaskProgress progress={row.progress} />{" "}
                </TableCell> */}
                <TableCell align="center">{row.dueDate}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Rating
                      name="simple-controlled"
                      value={row.priority}
                      onChange={(e) => {
                        setTasks({ ...tasks, priority: e.target.value });
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => onDelete(row.id)}>
                    <DeleteIcon sx={{ fontSize: "large" }} />
                  </IconButton>
                  <IconButton>
                    <TaskUpdate
                      sx={{ fontSize: "large" }}
                      task={row}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      optionsStatus={optionsStatus}
                      optionsPriority={optionsPriority}
                      optionsOwner={optionsOwner}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Actions
                      </Typography>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Completed Date</TableCell>
                            <TableCell>Owner</TableCell>
                          </TableRow>
                        </TableHead>

                        {subtasks.map((subtask) => (
                          <>
                            {subtask.taskName === row.title && (
                              <TableBody>
                                {/* {row.history.map((historyRow) => ( */}
                                <TableRow key={subtask.id}>
                                  <TableCell component="th" scope="row">
                                    {subtask.description}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {subtask.status}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {subtask.completedDate}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {subtask.owner}
                                  </TableCell>
                                </TableRow>
                                {/* ))} */}
                              </TableBody>
                            )}
                          </>
                        ))}
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
