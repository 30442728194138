import React, { useState } from 'react';
import TaskList from './TaskList';
import TaskTable from './TaskTable';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Form from 'react-bootstrap/Form';
import EditIcon from '@mui/icons-material/Edit';

import DialogContentText from '@mui/material/DialogContentText';

  

export default function TaskUpdate({ task, onDelete, onEdit,  optionsStatus, optionsPriority, optionsOwner }) {

    const [editedTask, setEditedTask] = useState({ ...task });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
      };


    const handleSaveEdit = () => {
        onEdit(editedTask);
    };

  //   const [pipelineData, setPipelineData] = useState( props.opp  );

  //   // Persist new Pipeline
  // const handleUpdate = async () => {

  //   try {

  //     const updatedTask = {
  //       id: pipelineData.id,  
  //       oppName: pipelineData.oppName,  
  //       oppOwner: pipelineData.oppOwner, 
  //       oppType: pipelineData.oppType,  
  //       oppRequestDate: pipelineData.oppRequestDate,  
  //       oppOrderDate: pipelineData.oppOrderDate,  
  //       oppProducts: pipelineData.oppProducts,  
  //       oppAmount: pipelineData.oppAmount,  
  //       oppStage: pipelineData.oppStage,
  //       oppIndustry: pipelineData.oppIndustry,
  //       oppComments: pipelineData.oppComments
  //     };

  //     await API.graphql(graphqlOperation(updatePipeline, { input: updatedTask }));
      
  //     // console.log("Update Result:", updatedTask);

  //     // You can also show a success message or navigate to another page after updating.
  //   } catch (error) {
  //     console.error("Error updating task", error);
  //   }
  //   handleClose()
  //   window.location.reload(true)
  // };

  return (
    <div>
      <EditIcon sx={{ fontSize: 20 }}  onClick={handleClickOpen}/>
      {/* <Button startIcon={<EditIcon/>} onClick={handleClickOpen}> */}
      {/* </Button> */}
      <Dialog
        // fullWidth={true}
        // fullScreen
        maxWidth='sm'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Update Task</DialogTitle>
        <DialogContent>
          <DialogContentText>


            <Grid container spacing={2}>
                <Grid item xs={8}>
                <TextField
                    id="title"
                    label="Task Title"
                    fullWidth
                    value={editedTask.title}
                    onChange={(e) => setEditedTask({ ...editedTask, title: e.target.value })}
                />
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="owner"
                    label="Task Owner"
                    select
                    fullWidth
                    value={editedTask.owner}
                    onChange={(e) => setEditedTask({ ...editedTask, owner: e.target.value })}
                >
                    {optionsOwner.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                        {option.name}
                    </MenuItem>
                    ))}
                </TextField>
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="status"
                    label="Task Status"
                    select
                    fullWidth
                    value={editedTask.status}
                    onChange={(e) => setEditedTask({ ...editedTask, status: e.target.value })}
                >
                    {optionsStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="progress"
                    label="Task Progress (%)"
                    fullWidth
                    value={editedTask.progress}
                    onChange={(e) => setEditedTask({ ...editedTask, progress: e.target.value })}
                    InputProps={{
                    endAdornment: '%',
                    }}
                />
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="deadline"
                    label="Task Deadline"
                    type="date"
                    fullWidth
                    value={editedTask.deadline}
                    onChange={(e) => setEditedTask({ ...editedTask, deadline: e.target.value })}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="priority"
                    label="Task Priority"
                    select
                    fullWidth
                    value={editedTask.priority}
                    onChange={(e) => setEditedTask({ ...editedTask, priority: e.target.value })}
                >
                    {optionsPriority.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
                </Grid>
            </Grid>
    
      <br></br>
   
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveEdit}>Submit</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}