import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Grid } from "@mui/material";
import TaskTable2 from "./TaskTable2";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Stack from "@mui/material/Stack";

import { getColorList, getRandomHexColor } from "./getColorList";
import { compareAsc, format } from "date-fns";

import {
  optionsStatus,
  optionsPriority,
  optionsOwner,
} from "./ProjectFormulas";
import { tasksInput, projectsInput, subtasksInput } from "./ProjectDataset";

import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

import { Amplify } from "aws-amplify";
import amplifyconfig from "../../aws-exports";
import { ConsoleLogger } from "aws-amplify/utils";
Amplify.configure(amplifyconfig);
const client = generateClient();




const TaskApp = () => {
  const [projects, setProjects] = useState(projectsInput);

  // const [tasks, setTasks] = useState(tasksInput);
  // const [subtasks, setSubTasks] = useState(subtasksInput);

  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    getTasks();
  }, []);

  const [subTasks, setSubTasks] = useState([]);
  useEffect(() => {
    getSubTasks();
  }, []);

  const [newTask, setNewTask] = useState({});

  const handleAddTask = () => {
    setTasks((prevTasks) => [...prevTasks, { ...newTask, id: Date.now() }]);
    setNewTask({ title: "", description: "" });
    handleClose();
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  const handleEditTask = (editedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === editedTask.id ? editedTask : task))
    );
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const getTasks = async () => {
    console.log("here")
    try {
      const tasksData = await client.graphql({ query: queries.listTasks });
      const tasksList = tasksData.data.listTasks.items;
      console.log("here")
      setTasks(tasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getSubTasks = async () => {
    try {
      const subTasksData = await client.graphql({
        query: queries.listSubtasks,
      });
      const subTasksList = subTasksData.data.listSubtasks.items;
      setSubTasks(subTasksList);
    } catch (err) {
      console.log("error", err);
    }
  };

  const randomColor = getRandomHexColor();

  const color = getColorList();

  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = () => {
    const sortedData = [...tasks].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.title < b.title ? -1 : 1;
      } else {
        return a.title > b.title ? -1 : 1;
      }
    });

    setTasks(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


  return (
    <>
      <React.Fragment>
        <Stack direction="row" spacing={2}>
          <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
            <AddIcon />
          </Fab>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll="body"
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const email = formJson.email;
                handleClose();
              },
            }}
          >
            <DialogTitle>Add a Task</DialogTitle>
            <DialogContent>
              <br></br>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="title"
                    label="Task Title"
                    fullWidth
                    value={newTask.title}
                    onChange={(e) =>
                      setNewTask({ ...newTask, title: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    label="Task Description"
                    fullWidth
                    // multiline
                    // rows={4}
                    value={newTask.description}
                    onChange={(e) =>
                      setNewTask({ ...newTask, description: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="owner"
                    label="Task Owner"
                    select
                    fullWidth
                    value={newTask.owner}
                    onChange={(e) =>
                      setNewTask({ ...newTask, owner: e.target.value })
                    }
                  >
                    {optionsOwner.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="status"
                    label="Task Status"
                    select
                    fullWidth
                    value={newTask.status}
                    onChange={(e) =>
                      setNewTask({ ...newTask, status: e.target.value })
                    }
                  >
                    {optionsStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="progress"
                    label="Task Progress (%)"
                    fullWidth
                    value={newTask.progress}
                    onChange={(e) =>
                      setNewTask({ ...newTask, progress: e.target.value })
                    }
                    InputProps={{
                      endAdornment: "%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="deadline"
                    label="Task Deadline"
                    type="date"
                    fullWidth
                    value={newTask.deadline}
                    onChange={(e) =>
                      setNewTask({ ...newTask, deadline: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="priority"
                    label="Task Priority"
                    select
                    fullWidth
                    value={newTask.priority}
                    onChange={(e) =>
                      setNewTask({ ...newTask, priority: e.target.value })
                    }
                  >
                    {optionsPriority.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleAddTask}>Submit</Button>
            </DialogActions>
          </Dialog>
          <Button
            // variant="outlined"
            // color="primary"
            sx={{
              backgroundColor: "#ECECEC ",
              color: "black",
              borderRadius: "10px",
            }}
            onClick={handleSort}
            startIcon={
              sortOrder === "asc" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            }
          >
            Sort by title
          </Button>
          <Button
            // variant="outlined"
            // color="primary"
            sx={{
              backgroundColor: "#ECECEC ",
              color: "black",
              borderRadius: "10px",
            }}
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button>
        </Stack>
      </React.Fragment>
      <br></br>
      <br></br>
      {/* <TaskTable tasks={tasks} projects={projects} onDelete={handleDeleteTask} onEdit={handleEditTask} 
        optionsStatus={optionsStatus} optionsPriority={optionsPriority} optionsOwner={optionsOwner} /> */}

      <TaskTable2
        tasksTable={tasks}
        subtasks={subTasks}
        projects={projects}
        onDelete={handleDeleteTask}
        onEdit={handleEditTask}
        optionsStatus={optionsStatus}
        optionsPriority={optionsPriority}
        optionsOwner={optionsOwner}
      />
    </>
  );
};

export default TaskApp;
