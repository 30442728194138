import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import EventCard from './EventCard'

const EventPage = () => {

    const handleButtonClick = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdO7wbRCLuXy-3H29UTv7W6Y2Whadm0jF5Yi8B11xsGX8qHAQ/viewform?pli=1', '_blank');
      };

  return (
    <div>
        <CssBaseline />
        {/* Header */}
        {/* <Paper elevation={3} sx={{ padding: '20px', backgroundColor: 'rgba(77,77,77,1)', color: '#ffffff' }}>
            
            <Typography variant="h4" component="div" gutterBottom>
                Próximos Evento
            </Typography>
            <Typography variant="body1" component="div">
                Join us for an unforgettable experience!
            </Typography>
         
        </Paper>
        <br></br> */}
        <EventCard />

        <br></br>

        {/* Footer */}
        <footer>
            <Container>
            <Typography variant="body2" color="text.secondary" align="center">
                © {new Date().getFullYear()}. All rights reserved.
            </Typography>
            </Container>
        </footer>
    </div>
    
    // <div>
    //   <CssBaseline />
    //   {/* Header */}
    //   <Paper elevation={3} sx={{ padding: '20px', backgroundColor: 'rgba(77,77,77,1)', color: '#ffffff' }}>
    //     <Container>
    //       <Typography variant="h4" component="div" gutterBottom>
    //         Próximo Evento
    //       </Typography>
    //       <Typography variant="body1" component="div">
    //         Join us for an unforgettable experience!
    //       </Typography>
    //     </Container>
    //   </Paper>

    //   {/* Event Details */}
    //   <Container sx={{ marginTop: '20px' }}>
    //     <Paper elevation={3} sx={{ padding: '20px' }}>
        //   <Typography variant="h5" component="div" gutterBottom>
        //     Detalhes do Evento
        //   </Typography>
        //   <Typography variant="body1" component="div">
        //     Date: 21/03/2024 a 22/03/2024
        //   </Typography>
        //   <Typography variant="body1" component="div">
        //     Location: Online
        //   </Typography>
        //   <Typography variant="body1" component="div">
        //     Description: FUNDAMENTOS DA METROLOGIA E SUAS APLICAÇÕES
        //   </Typography>
    //     </Paper>
    //   </Container>

    //   {/* Schedule */}
    //   <Container sx={{ marginTop: '20px' }}>
    //     <Paper elevation={3} sx={{ padding: '20px' }}>
        //   <Typography variant="h5" component="div" gutterBottom>
        //     Conteúdo Programático
        //   </Typography>
        //   {/* Add your schedule components here */}
        //   <Typography variant="body1" component="div" sx={{margin:2}}>
        //     <TaskAltIcon sx={{marginRight:2}} />
        //     Metrologia - conceitos básicos
        //   </Typography>
        //   <Typography variant="body1" component="div" sx={{margin:2}}>
        //     <TaskAltIcon sx={{marginRight:2}}  />
        //     Áreas de atuação da Metrologia
        //   </Typography>
        //   <Typography variant="body1" component="div" sx={{margin:2}}>
        //     <TaskAltIcon sx={{marginRight:2}}  />
        //     Sistema Internacional de Unidades - SI
        //   </Typography>
        //   <Typography variant="body1" component="div" sx={{margin:2}}>
        //     <TaskAltIcon sx={{marginRight:2}}  />
        //     Vocabulário Internacional de Metrologia - VIM2012
        //   </Typography>
        //   <Typography variant="body1" component="div" sx={{margin:2}}>
        //     <TaskAltIcon sx={{marginRight:2}}  />
        //     Interpretação de Certificado de Calibração
        //   </Typography>
    //     </Paper>
    //   </Container>

    //   {/* Registration */}
    //   <Container sx={{ marginTop: '20px' }}>
    //     <Paper elevation={3} sx={{ padding: '20px' }}>
    //       <Typography variant="h5" component="div" gutterBottom>
    //         Register Now!
    //       </Typography>
    //       <Typography variant="body1" component="div">
    //         Don't miss out on this amazing event. Register now to secure your spot.
    //       </Typography>
        //   <Button  
        //     onClick={handleButtonClick}
        //     variant="contained" 
        //     color="primary" 
        //     sx={{ marginTop: '10px' }}>
        //     Register
        //   </Button>
    //     </Paper>
    //   </Container>
    //   <br></br>

    //   {/* Footer */}
    //   <footer>
    //     <Container>
    //       <Typography variant="body2" color="text.secondary" align="center">
    //         © {new Date().getFullYear()}. All rights reserved.
    //       </Typography>
    //     </Container>
    //   </footer>
    // </div>
  );
};

export default EventPage;
