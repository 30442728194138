import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Paper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import tasksData from "./Tasks17025.json";

function CollapsibleTableRow({ row }) {
  const [open, setOpen] = useState(false);

  // Function to get the first letter of the name
  const getInitials = (name) => {
    if (!name) return "";
    const names = name.split(" ");
    const initials = names
      .map((namePart) => namePart.charAt(0).toUpperCase())
      .join("");
    return initials;
  };

  // Function to get color based on priority
  const getPriorityColor = (priority) => {
    switch (priority) {
      case "Urgent":
        return "#FF5252"; // Light red
      case "Medium":
        return "#FFC107"; // Amber
      case "Low":
        return "#4CAF50"; // Green
      case "Important":
        return "#2196F3"; // Blue
      default:
        return "#9E9E9E"; // Gray
    }
  };

  const labelColorMap = {
    'Processo': '#FFAB91',         // Light orange
    'Recursos': '#C8E6C9',         // Light green
    'Sistema de Gestão': '#BBDEFB', // Light blue
    'Estrutura': '#E1BEE7',        // Light purple
    'Gerais': '#FFE082',           // Light yellow
  };
  return (
    <>
      <TableRow>
        <Box
          sx={{
            width: "20px",
            height: "80px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            backgroundColor: "rgba(18,85,112,0.2)",
          }}
        ></Box>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row["Task Name"]}</TableCell>
        {
          (row["Completed Checklist Items"] == "" ? (
            <TableCell align="left">
              {row["Completed Checklist Items"]} 
            </TableCell>
          ) : (
            <TableCell align="center">
              {row["Completed Checklist Items"]} ({row.statusTask})
            </TableCell>
          ))
        }

        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            <Chip label={row["Bucket Name"]} variant="outlined" size="small" />
            <Chip
              label={row["Priority"]}
              size="small"
              sx={{ 
                backgroundColor: getPriorityColor(row["Priority"]),
                // color: '#fff' // White text for contrast
              }}
            />
            {row["Labels"] && (
              <Chip label={row["Labels"]}
              size="small"
              sx={{ backgroundColor: labelColorMap[row["Labels"]] || '#e0e0e0'}} />
            )}
            <Avatar sx={{ width: 24, height: 24, fontSize: "0.75rem" }}>
              {getInitials(row["Created By"])}{" "}
            </Avatar>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Tarefas
              </Typography>
              <Table size="small" aria-label="details">
                <TableBody>
                  <FormGroup>
                    {Array.isArray(row["Checklist Items"]) &&
                      row["Checklist Items"].map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <FormControlLabel control={<Checkbox />} />
                          </TableCell>
                          <TableCell>{item}</TableCell>
                        </TableRow>
                      ))}
                  </FormGroup>
                  {Array.isArray(row["Description"]) &&
                    row["Description"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          Obs. {index + 1}
                        </TableCell>
                        <TableCell>{item}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsibleTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        {/* <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align="left">Nome</TableCell>
            <TableCell align="center">Status </TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {data.map((row, index) => (
            <>
              <CollapsibleTableRow key={index} row={row} />
              <br></br>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function App() {
  function calculatePercentage(fraction) {
    // Step 1: Split the string into numerator and denominator
    const [numerator, denominator] = fraction.split("/");

    // Step 2: Convert the strings to numbers
    const num = parseFloat(numerator);
    const denom = parseFloat(denominator);

    // Step 3: Check if denominator is zero to avoid division by zero
    if (denom === 0) {
      throw new Error("Denominator cannot be zero.");
    }

    // Step 4: Calculate the percentage
    const percentage = (num / denom) * 100;

    // Return the percentage formatted to two decimal places
    return percentage.toFixed(0) + "%";
  }

  // Example: Adding a new column based on existing data
  const updatedTasksDataWithDynamicValue = tasksData.map((task) => ({
    ...task,
    statusTask: calculatePercentage(task["Completed Checklist Items"]), // Example of dynamic data based on existing property
  }));
  const data = updatedTasksDataWithDynamicValue;

  return (
    <div>
      <CollapsibleTable data={data} />
    </div>
  );
}

export default App;
