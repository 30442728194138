import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import StraightenIcon from "@mui/icons-material/Straighten";
import SchoolIcon from "@mui/icons-material/School";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "@aws-amplify/ui-react/styles.css";
import Typography from "@mui/material/Typography";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function HomePage() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variant1 = width < 700 ? "h6" : "h4";
  const variant2 = width < 700 ? "h6" : "h5";

  return (
    <div style={{ minHeight: 570 }}>
      <Grid container spacing={3} mt={3}>
        {/* Seção de Cabeçalho */}
        <Grid item xs={12}>
          <Typography
            variant={variant1}
            align="center"
            sx={{ fontWeight: "bold" }}
            gutterBottom
          >
            Bem-vindo à Excelsior Metrologia
          </Typography>
          <Typography variant={variant2} align="center" paragraph>
            Oferecemos serviços de metrologia de alta qualidade para atender às
            suas necessidades.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Fundada em julho de 2022, a Excelsior Serviços Metrológicos foi
            criada com o intuito de disseminar o conhecimento metrológico dentro
            das indústrias, prestadores de serviço de calibração, hospitais e
            engenharias clínicas, com foco nos laboratórios de calibração
            acreditados e postulantes a acreditação da norma ABNT NBR ISO/IEC
            17025:2017.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Saiba mais sobre nossos serviços e como podemos ajudá-lo a garantir
            a precisão e a qualidade de seus produtos.
          </Typography>
        </Grid>

        {/* Seção de Serviços */}
        <Grid item xs={12}>
          <Typography variant={variant1} align="center" gutterBottom>
            Nossos Serviços
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 150, backgroundColor: "rgba(18,85,112,0.2)", }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <StraightenIcon sx={{ marginRight: 1 }} />
                    Auditoria / Metrologia
                  </Typography>
                  <Typography variant="body2">
                    Realizamos consutoria em metrologia para garantir a precisão
                    dos resultados. ABNT NBR ISO/IEC 17025:2017
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 150, backgroundColor: "rgba(18,85,112,0.2)", }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <BusinessCenterIcon sx={{ marginRight: 1 }} />
                    Consultoria Técnica
                  </Typography>
                  <Typography variant="body2">
                    Oferecemos consultoria em metrologia para ajudar sua empresa
                    a implementar melhores práticas de medição.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 150, backgroundColor: "rgba(18,85,112,0.2)", }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <SchoolIcon sx={{ marginRight: 1 }} />
                    Treinamentos
                  </Typography>
                  <Typography variant="body2">
                    Oferecemos treinamento em metrologia para ajudar sua empresa
                    a implementar melhores práticas de medição.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {/* Seção de Utilidades */}
        <Grid item xs={12}>
          <Typography variant={variant1} align="center" gutterBottom>
            Utilidades
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 80, backgroundColor: "rgba(18,85,112,0.2)", }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <SwapHorizIcon sx={{ marginRight: 1 }} />
                    Conversor de Pressão
                  </Typography>
                  <CardActions
                    sx={{
                      // display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // background: "rgba(18,85,112,1)",
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "rgba(18,85,112,1)",
                        color: "#ffffff",
                        "&:hover": { backgroundColor: "rgba(18,85,112,0.8)" },
                      }}
                      component={Link}
                      Link
                      to="/conversor"
                    >
                      Clique Aqui
                    </Button>
                    {/* {actions.map((action, index) => (
            <Button key={index} size="small" onClick={action.onClick}>
              {action.label}
            </Button>
          ))} */}
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 150 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <BusinessCenterIcon sx={{ marginRight: 1 }} />
                    Consultoria Técnica
                  </Typography>
                  <Typography variant="body2">
                    Oferecemos consultoria em metrologia para ajudar sua empresa
                    a implementar melhores práticas de medição.
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ minHeight: 150 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <SchoolIcon sx={{ marginRight: 1 }} />
                    Treinamentos
                  </Typography>
                  <Typography variant="body2">
                    Oferecemos treinamento em metrologia para ajudar sua empresa
                    a implementar melhores práticas de medição.
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>

        {/* Seção de Chamada para Ação */}
        <Grid item xs={12} align="center">
          <Typography variant="body1" paragraph>
            Pronto para melhorar a qualidade das suas medições?
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomePage;
