import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SchoolIcon from '@mui/icons-material/School';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/system/Unstable_Grid';   

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleButtonClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdO7wbRCLuXy-3H29UTv7W6Y2Whadm0jF5Yi8B11xsGX8qHAQ/viewform?pli=1', '_blank');
  };

  return (


    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>				
	    <Grid item xs={2} sm={4} md={4} >
            <Card sx={{ maxWidth: 645 }}>
            <CardHeader
                avatar={
                <SchoolIcon sx={{ bgcolor: red[500] }} aria-label="recipe">
                    R
                </SchoolIcon>
                }
                action={
                <IconButton aria-label="settings">
                    <MoreVertIcon />
                </IconButton>
                }
                title="CALIBRAÇÃO DE MANÔMETROS ANALÓGICOS E DIGITAIS"
                subheader="25-26 de Março de 2024"
            />
            <CardMedia
                component="img"
                height= "450" 
                image="https://media.licdn.com/dms/image/D4E22AQFrl4FyDGXsJg/feedshare-shrink_800/0/1709667742577?e=1714003200&v=beta&t=EkJ_aiMxzaRiImzSKmNlJqnkeNUD53k8KKa7BB1fqMM"
                alt="Paella dish"
            />
            <CardContent style={{ height: 200, overflowY: 'auto' }}>

                <Typography variant="h5" component="div" gutterBottom>
                    Conteúdo Programático
                </Typography>
                {/* Add your schedule components here */}
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}} />
                    Calibração de Manômetro analógico
                </Typography>
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}}  />
                    Calibração de Manômetro digital
                </Typography>

            </CardContent>
            <CardActions disableSpacing>
                <Button  
                    onClick={handleButtonClick}
                    variant="contained" 
                    color="primary" 
                    sx={{ marginTop: '10px' }}>
                    Click aqui para fazer a sua inscrição
                </Button>
                <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ height: 200, overflowY: 'auto' }}>
                <Typography paragraph>
                    Detalhes do Evento
                </Typography>
                <Typography paragraph>
                    Date: 25/03/2024 a 23/03/2024
                </Typography>
                <Typography paragraph>
                    Location: Campinas,SP
                </Typography>
                </CardContent>
            </Collapse>
            </Card>
        </Grid>
        <Grid item xs={2} sm={4} md={4} >
            <Card sx={{ maxWidth: 645 }}>
            <CardHeader
                avatar={
                <SchoolIcon sx={{ bgcolor: red[500] }} aria-label="recipe">
                    R
                </SchoolIcon>
                }
                action={
                <IconButton aria-label="settings">
                    <MoreVertIcon />
                </IconButton>
                }
                title="FUNDAMENTOS DA METROLOGIA E SUAS APLICAÇÕES"
                subheader="21 de Março de 2024"
            />
            <CardMedia
                component="img"
                height= "450" 
                image="https://media.licdn.com/dms/image/D4D22AQG3IyP5Txd3PQ/feedshare-shrink_800/0/1709941898809?e=1712793600&v=beta&t=zCS9gevjPPcou03xaW02a6GKPQXAe7327gjKq8kYAvE"
                alt="Paella dish"
            />
            <CardContent style={{ height: 200, overflowY: 'auto' }}>

                <Typography variant="h5" component="div" gutterBottom>
                    Conteúdo Programático
                </Typography>
                {/* Add your schedule components here */}
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}} />
                    Metrologia - conceitos básicos
                </Typography>
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}}  />
                    Áreas de atuação da Metrologia
                </Typography>
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}}  />
                    Sistema Internacional de Unidades - SI
                </Typography>
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}}  />
                    Vocabulário Internacional de Metrologia - VIM2012
                </Typography>
                <Typography variant="body1" component="div" sx={{margin:1}}>
                    <TaskAltIcon sx={{marginRight:2}}  />
                    Interpretação de Certificado de Calibração
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Button  
                    onClick={handleButtonClick}
                    variant="contained" 
                    color="primary" 
                    sx={{ marginTop: '10px' }}
                    disabled
                    >
                    Click aqui para fazer a sua inscrição
                </Button>
                <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ height: 200, overflowY: 'auto' }}>
                <Typography paragraph>
                    Detalhes do Evento
                </Typography>
                <Typography paragraph>
                    Date: 21/03/2024 a 22/03/2024
                </Typography>
                <Typography paragraph>
                    Location: Online
                </Typography>
                <Typography paragraph>
                    Description: FUNDAMENTOS DA METROLOGIA E SUAS APLICAÇÕES
                </Typography>
                </CardContent>
            </Collapse>
            </Card>
        </Grid>

    </Grid>
  );
}