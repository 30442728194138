import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const KanbanBoard = () => {
  const [tasks, setTasks] = useState({
    todo: ['Task 1', 'Task 2', 'Task 3'],
    inProgress: ['Task 4', 'Task 5'],
    done: ['Task 6'],
  });

  const moveTask = (task, from, to) => {
    const updatedTasks = { ...tasks };
    const index = updatedTasks[from].indexOf(task);
    updatedTasks[from].splice(index, 1);
    updatedTasks[to].push(task);
    setTasks(updatedTasks);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Card sx={{ backgroundColor:"rgba(12,85,112,0.4)" }} >
          <CardContent>
            <h2>To Do</h2>
            {tasks.todo.map((task, index) => (
              <div key={index}>
                <Card sx={{ marginBottom: 2 }} > 
                  <CardContent>
                    {task}
                    <Button onClick={() => moveTask(task, 'todo', 'inProgress')}>Move to In Progress</Button>
                  </CardContent>
                </Card>
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ backgroundColor:"rgba(12,85,112,0.4)" }} >
          <CardContent>
            <h2>In Progress</h2>
            {tasks.inProgress.map((task, index) => (
              <div key={index}>
                <Card sx={{ marginBottom: 2 }} >
                  <CardContent>
                    {task}
                    <Button onClick={() => moveTask(task, 'inProgress', 'done')}>Move to Done</Button>
                  </CardContent>
                </Card>
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ backgroundColor:"rgba(12,85,112,0.4)" }} >
          <CardContent>
            <h2>Done</h2>
            {tasks.done.map((task, index) => (
              <div key={index}>
                <Card sx={{ marginBottom: 2 }} >
                  <CardContent>
                    {task}
                  </CardContent>
                </Card>
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default KanbanBoard;
